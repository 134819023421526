import "./App.css";
import { useState, useEffect } from "react";
import StartScreen from "./Components/StartScreen";
import PopupTip from "./Components/Overlay/PopupTip";
import FloorBar from "./Components/FloorBar";
import ElevatorScreen from "./Components/ElevatorScreen";
import CharacterSlider from "./Components/Slider/CharacterSlider";
import EndScreen from "./Components/EndScreen";

function App() {
  const [score, setScore] = useState(0);
  const [showStartScreen, setShowStartScreen] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const [isFloorChanging, setIsFloorChanging] = useState(false);
  const [showCharacterSlider, setShowCharacterSlider] = useState(false);
  const [mobileClickedChar, setMobileClickedChar] = useState(false);
  const [clickedCharId, setClickedCharId] = useState(null);
  const [showEndScreen, setShowEndScreen] = useState({
    status: false,
    result: "bad",
  });
  const [characterList, setCharacterList] = useState([
    {
      id: 4,
      name: "circulus",
      floorName: "Отдел КРУГО- производителей",
      floorNumber: 9,
      zindexOrder: 6,
      areaCoords:
        "55,486,48,470,44,422,36,396,28,391,24,373,30,341,21,334,4,328,1,318,7,300,8,267,16,247,23,245,12,222,36,233,32,208,23,198,31,185,43,167,51,166,45,131,57,127,70,127,73,163,91,177,96,189,96,228,111,223,109,233,109,238,121,249,123,280,128,306,110,319,101,338,110,371,103,385,92,391,87,425,91,433,86,450,76,458,72,481",
      answerText: {
        right: "Вот это работа, аж голова кругом!",
        wrong: "Только не сажайте в угол!",
      },
    },
    {
      id: 8,
      name: "lemon",
      floorName: "Отдел морщкетинга",
      floorNumber: 8,
      zindexOrder: 3,
      areaCoords:
        "223,474,224,455,220,445,224,436,228,432,214,385,198,370,189,341,187,307,190,290,177,272,186,253,195,246,199,235,216,214,242,198,260,199,280,209,299,236,310,254,316,277,307,295,315,316,315,359,315,370,319,405,316,412,295,417,302,435,296,444,319,470,315,479,280,467,229,480",
      answerText: {
        right: "Плюс вайб!",
        wrong: "Как-то здесь кисло…",
      },
    },
    {
      id: 5,
      name: "aloe",
      floorName: "Отдел разруливания вопросиков",
      floorNumber: 7,
      zindexOrder: 7,
      areaCoords:
        "100,469,119,309,109,305,109,298,120,289,112,250,106,217,85,189,97,160,104,130,115,138,133,108,156,87,171,60,195,108,205,111,208,145,220,126,227,161,214,209,211,239,204,286,219,297,217,306,224,471,215,474,195,453,181,308,147,307,124,458,109,475",
      answerText: {
        right: "Буду разруливать вопросики ЗА ДЕНЬГИ",
        wrong: "А поговорить?..",
      },
    },
    {
      id: 6,
      name: "cat",
      floorName: "Кис-кис-админская",
      floorNumber: 6,
      zindexOrder: 4,
      areaCoords:
        "189,318,198,304,214,293,207,265,214,247,211,222,220,178,202,171,195,157,200,115,208,123,227,124,246,114,254,123,266,119,293,115,289,129,298,139,312,138,333,148,324,164,326,174,321,184,312,189,330,236,333,263,321,295,329,335,325,341,299,293,264,303,229,301,198,319",
      answerText: {
        right: "Остаюсь здесь, не пишите мне никогда!",
        wrong: "Пфф, у них даже мышек нет…",
      },
    },
    {
      id: 2,
      name: "bulb",
      floorName: "Отдел просвещения",
      floorNumber: 5,
      zindexOrder: 10,
      areaCoords:
        "129,142,125,118,132,98,117,74,115,48,127,23,127,5,137,-1,168,2,180,9,199,23,213,45,211,73,196,98,201,120,203,149,174,153,150,149",
      answerText: {
        right: "Вот это огонь!",
        wrong: "Тёмные времена настают…",
      },
    },
    {
      id: 9,
      name: "owl",
      floorName: "Отдел сбыта мечт",
      floorNumber: 4,
      zindexOrder: 1,
      areaCoords:
        "107,498,29,490,6,467,3,443,8,410,15,407,12,387,24,372,21,348,54,325,81,318,107,327,120,343,131,359,131,367,140,367,149,375,161,422,159,431,151,433,158,476,143,499",
      answerText: {
        right: "Ловко меня доставили — вот бы тоже что-нибудь доставить!",
        wrong: "До момента, когда я отсюда улечу, 3, 2, 1…",
      },
    },
    {
      id: 7,
      name: "beaver",
      floorName: "Отдел трудоуСТРОЙСТВА",
      floorNumber: 3,
      zindexOrder: 5,
      areaCoords:
        "116,480,105,424,93,412,88,391,94,355,91,314,99,265,104,246,92,244,108,223,112,203,143,177,168,178,193,200,203,221,214,222,227,236,233,280,227,362,234,392,231,416,220,428,232,466,188,474,183,431,141,428,150,462",
      answerText: {
        right: "Ух ты, вот это трудоуСТРОЙСТВО!",
        wrong: "Рабочий день ещё не начался, а уже ломает что-то строить…",
      },
    },
    {
      id: 1,
      name: "shark",
      floorName: "Отдел акул",
      floorNumber: 2,
      zindexOrder: 8,
      areaCoords:
        "34,254,21,176,13,93,19,56,34,36,47,35,69,46,85,45,105,58,94,68,105,81,105,90,127,132,137,179,140,243,121,262,77,269",
      answerText: {
        right:
          "Только сейчас — ваша персональная скидка на самого зубастого сотрудника!",
        wrong: "Кажется, в этом море мало рыбы…",
      },
    },
    {
      id: 3,
      name: "rice",
      floorName: (
        <>
          Отдел <br /> рис-менеджмента
        </>
      ),
      floorNumber: 1,
      zindexOrder: 9,
      areaCoords:
        "203,206,204,164,216,142,219,109,227,99,219,78,233,79,221,57,219,15,233,11,243,50,259,37,289,34,314,59,316,72,325,103,322,139,285,231,251,226,227,218",
      answerText: {
        right: "Срочно засыпьте меня в этот ноутбук — и я его спасу!",
        wrong: "СобеРИСь, рабочий день только начался",
      },
    },
    {
      id: 10,
      name: "crab",
      floorName: "Подводная бульбульгалтерия",
      floorNumber: -1,
      zindexOrder: 2,
      areaCoords:
        "127,485,112,322,125,318,160,328,258,308,329,337,318,478,266,489,216,481,204,498,188,501,168,494,191,478,174,478,141,489",
      answerText: {
        right: "Считаю, это успех!",
        wrong: "Обсчитались на пару этажей!",
      },
    },
  ]);

  useEffect(() => {
    const loader = document.querySelector(".loader");
    if (loader) {
      loader.remove();
    }

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const comImgs = [
      "/assets/comix/owlComix1.png",
      "/assets/comix/owlComix2.png",
      "/assets/comix/owlComix3.png",
      "/assets/comix/owlComix4.png",
      "/assets/comix/sharkComix1.png",
      "/assets/comix/sharkComix2.png",
      "/assets/comix/sharkComix3.png",
      "/assets/comix/sharkComix4.png",
      "/assets/comix/aloeComix1.png",
      "/assets/comix/aloeComix2.png",
      "/assets/comix/aloeComix3.png",
      "/assets/comix/aloeComix4.png",
    ];

    comImgs.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  function closeStartScreenHandler() {
    setShowStartScreen(false);
    window.goal('btn_start');
    document.getElementsByClassName('bgGrad')[0].classList.remove('bgGrad');
  }

  const closePopupHandler = (isFin = false) => {
    setShowPopup(false);
    window.goal('floor_-1');
    if (!isFin) {
      window.goal('tutor_skip');
    }
  }

  function characterClickHandler(e) {
    const targetId = e.currentTarget.id;
    const userViewportWidth = document.documentElement.clientWidth;
    if (userViewportWidth <= 1200) {
      setMobileClickedChar(Number(targetId));
      setTimeout(() => {
        setMobileClickedChar(false);
        setShowCharacterSlider(true);
        setClickedCharId(targetId);
      }, 1000);
      return;
    }

    setShowCharacterSlider(true);
    setClickedCharId(e.currentTarget.id);
  }

  function onCloseCharacterSliderHandler() {
    setClickedCharId(null);
    setShowCharacterSlider(false);
  }

  function onOpenCharacterSliderHandler() {
    setClickedCharId(null);
    setShowCharacterSlider(true);
  }

  function nextFloorHandler() {
    if (characterList[characterList.length - 2]?.floorNumber) {
      window.goal(`floor_${characterList[characterList.length - 2]?.floorNumber}`);
    }
    setClickedCharId(null);
    setIsFloorChanging(true);
    setShowCharacterSlider(false);
    const newCharList = characterList.slice(0, characterList.length - 1);
    setTimeout(() => {
      setIsFloorChanging(false);
      setCharacterList(newCharList);
    }, 750);
  }

  function endingScreenHandler(score, isExit = false) {
    if (isExit) {
      window.goal('btn_exit')
    }
    window.goal('fin_total')
    window.goal(`fin_${score >= 7 ? 'good' : 'sad'}`)
    setShowEndScreen({ status: true, result: score >= 7 });
  }
  return (
    <>
      {!showEndScreen.status && (
        <div className={`app scale ${mobileClickedChar ? "freeze" : ""}`}>
          {showStartScreen && (
            <StartScreen onCloseStartScreen={closeStartScreenHandler} />
          )}
          {!showStartScreen && showPopup && (
            <PopupTip
              characterList={characterList}
              onClosePopup={closePopupHandler}
            />
          )}

          {!showStartScreen && (
            <div>
              <FloorBar
                currentFloor={characterList[characterList.length - 1]}
                nextFloor={characterList[characterList.length - 2]}
                initialTransition={showStartScreen}
                isFloorChanging={isFloorChanging}
              />
              <ElevatorScreen
                onExit={() => endingScreenHandler(null, true)}
                characterList={characterList}
                initialTransition={showStartScreen}
                isPopupClosed={!showPopup}
                isFloorChanging={isFloorChanging}
                onCharacterClick={characterClickHandler}
                onOpenCharacterSlider={onOpenCharacterSliderHandler}
                mobileClickedChar={mobileClickedChar}
              />
            </div>
          )}

          {showCharacterSlider && (
            <CharacterSlider
              onExit={endingScreenHandler}
              characterList={characterList}
              onRemoveCharacter={nextFloorHandler}
              onCloseSlider={onCloseCharacterSliderHandler}
              clickedCharId={clickedCharId}
              isFloorChanging={isFloorChanging}
              score={score}
              setNewScore={(result) => setScore((prev) => prev + result)}
            />
          )}
        </div>
      )}
      {showEndScreen.status && <EndScreen endResult={showEndScreen.result} />}
    </>
  );
}

export default App;
